<template>
  <div><br>
    <el-button size="mini" type="primary" @click="exportLabelBill" v-waves>导出标签账单</el-button>
    <el-divider content-position="left">账单信息</el-divider><br>
    <el-table :data="billInfoData" show-summary :summary-method="getSummaries" style="width: 50%" border>
      <el-table-column align="center" label="类型" prop="labelType"/>
      <el-table-column align="center" label="数量(套)" prop="labelCount"/>
      <el-table-column align="center" label="单价(元)" prop="unitPrice"/>
      <el-table-column align="center" label="总价" prop="totalPrice"/>
    </el-table>
    <el-divider content-position="left">
      账单详情
      <el-button type="text" style="margin-left: 10px" @click="editHistoryView">账单修改历史></el-button>
    </el-divider><br>
    <el-table :data="billDetailData" style="width: 100%" border>
      <el-table-column label="账单周期" align="center">{{ dateStr }}</el-table-column>
      <el-table-column v-if="labelType === 'SUBSTITUTE'" align="center" label="代垫客户" prop="dealerName"/>
      <el-table-column align="center" label="ODM供应商" prop="manufacturerName"/>
      <el-table-column align="center" label="订单批次" prop="orderBatch"/>
      <el-table-column align="center" label="账单类型" prop="payType.type"/>
      <el-table-column align="center" label="物料代码" prop="materialCode"/>
      <el-table-column align="center" label="型号" prop="modelName"/>
      <el-table-column align="center" label="类型" prop="modelType.title"/>
      <el-table-column align="center" label="标签数量(套)" prop="applyCount"/>
      <el-table-column align="center" label="单价(元)" prop="labelUnitPrice"/>
      <el-table-column align="center" label="合计(元)" prop="labelTotalPrice"/>
      <el-table-column label="操作" align="center">
        <template #default="scope">
          <el-button v-if="scope.row.applyCount != 0" type="text" @click="handleEdit(scope.row)">修改</el-button>
        </template>
      </el-table-column>
    </el-table>

    <el-dialog title="标签数量修改" v-model="dialogVisible" width="50%" center>
      <el-form ref="form" :model="formData" label-width="100px">
        <el-form-item label="ODM供应商">
          <span style="color:#7F7F7F; margin-left: 20px">{{ formData.manufacturerName }}</span>
        </el-form-item>
        <el-form-item label="订单批次">
          <span style="color:#7F7F7F; margin-left: 20px">{{ formData.orderBatch }}</span>
        </el-form-item>
        <el-form-item label="物料代码">
          <span style="color:#7F7F7F; margin-left: 20px">{{ formData.materialCode }}</span>
        </el-form-item>
        <el-form-item label="型号">
          <span style="color:#7F7F7F; margin-left: 20px">{{ formData.modelName }}</span>
        </el-form-item>
        <el-form-item label="原始标签数">
          <span style="color:#7F7F7F; margin-left: 20px">{{ formData.applyCount }}</span>
        </el-form-item>
        <el-form-item
            required
            label="核销标签数"
            prop="decLabelCount"
            :rules="[
                { required: true, message: '核销标签数不能为空' },
                { type: 'number', message: '核销标签数只能为数字' },
                ]">
          <el-input type="text" style="width: 50%;" v-model.number="formData.decLabelCount" clearable placeholder="请填写需要销掉的标签数" @input="handleInput"></el-input>
          <el-button type="primary" style="position: absolute;margin-left: 10px" @click="decAll">全部核销</el-button>
        </el-form-item>
        <el-form-item>
          <span style="color:red;">“核销标签”指供应商退单后，需要从“原始标签”里减去的那部分标签。</span>
        </el-form-item>
        <el-form-item
            required
            label="操作备注"
            prop="remark"
            :rules="[{ required: true, message: '备注不能为空' }]"
        >
          <el-input type="textarea" v-model="formData.remark"/>
        </el-form-item>
      </el-form>
      <template #footer>
      <span class="dialog-footer">
        <el-button @click="dialogVisible = false">取消</el-button>
        <el-button type="primary" @click="submitEdit('form')">确认修改</el-button>
      </span>
      </template>
    </el-dialog>

    <el-dialog v-model="dialogVisibleView" width="50%" center>
      <el-timeline>
        <el-timeline-item
            v-for="(item,index) in editLogList"
            :key="index"
            color="#0bbd87">
          <div>
            <span style="font-weight:bold;">ODM 供应商：</span>
            <span style="font-weight:bold;color:#409eff">【{{ item.manufacturer.name }}】</span>
          </div>
          <div class="top-8">
            <span style="font-weight:bold;">设备：</span>
            <span style="font-weight:bold;color:#409eff">【{{ item.model.name + '（' + item.model.materialCode + '）' }}】</span>
          </div>
          <div class="top-8">
            <span style="font-weight:bold;">标签数：</span>
            <span style="font-weight:bold;color:#409eff">【{{ item.sourceLabelCount }}】</span>
            <span style="font-weight:bold;">核销数：</span>
            <span style="font-weight:bold;color:red">【{{ item.decLabelCount }}】</span>
            <span style="font-weight:bold;">剩余数：</span>
            <span style="font-weight:bold;color:#409eff">【{{ item.targetLabelCount }}】</span>
          </div>
          <div class="top-8">
            <span style="font-weight:bold;">处理人：</span>
            <span>{{ item.account }}</span>
          </div>
          <div class="top-8">
            <span style="font-weight:bold;">处理时间：</span>
            <span>{{ item.updateTime }}</span>
          </div>
          <div class="top-8">
            <span style="font-weight:bold;">处理意见：</span>
            <span>{{ item.remark }}</span>
          </div>
        </el-timeline-item>
      </el-timeline>
    </el-dialog>
  </div>
</template>

<script>
import labelBillInfoApi from "@/apis/bill/labelBill/info";
import {ElMessage} from "element-plus";

export default {
  data() {
    return {
      id: '',
      labelType: 'SUBSTITUTE',
      billInfoData: [],
      billDetailData: [],
      dateStr: '',
      labelTypeName:'',
      accountName:'',
      listQuery: {
        date: '',
        labelBillIds: [],
        accountId: '',
        payType: '',
        dealerId: '',
        manufacturerId: ''
      },
      dialogVisible: false,
      dialogVisibleView: false,
      formData: {
        labelBillId: '',
        decLabelCount: '',
        remark: '',
      },
      editLogList: []
    }
  },
  created() {
    const labelBillInfo = JSON.parse(decodeURIComponent(this.$route.query.data))
    this.id = labelBillInfo.id
    this.labelType = labelBillInfo.payType.name
    this.labelTypeName = labelBillInfo.payType.type
    this.accountName = this.labelType === 'SUBSTITUTE' ? labelBillInfo.dealer.name : labelBillInfo.manufacturer.name
    this.dateStr = labelBillInfo.year + '年' + labelBillInfo.month + '月'
    this.listQuery = {
      date: labelBillInfo.date,
      accountId: labelBillInfo?.manufacturer?.accountId,
      payType: labelBillInfo.payType.name,
      dealerId: labelBillInfo?.dealer?.id,
      manufacturerId: labelBillInfo?.manufacturer?.id
    }
    this.getLabelBillInfo(this.id)
    console.log(labelBillInfo)
    this.getLabelBillDetail()
  },
  mounted() {
  },
  methods: {
    getLabelBillInfo(id) {
      labelBillInfoApi.getLabelBillInfo(id).then(res => {
        this.initBillInfoData(res.data)
      })
    },
    initBillInfoData(infoData) {
      this.billInfoData = []
      const kitchen = {
        labelType: '厨电',
        labelCount: infoData.kitchenLabelCount,
        unitPrice: infoData.kitchenLabelUnitPrice,
        totalPrice: (infoData.kitchenLabelCount * infoData.kitchenLabelUnitPrice).toFixed(2)
      }
      const home = {
        labelType: '小电',
        labelCount: infoData.homeLabelCount,
        unitPrice: infoData.homeLabelUnitPrice,
        totalPrice: (infoData.homeLabelCount * infoData.homeLabelUnitPrice).toFixed(2)
      }
      this.billInfoData.push(kitchen,home)
    },
    getSummaries(param) {
      const { columns, data } = param;
      const sums = [];
      columns.forEach((column, index) => {
        if (index === 0) {
          sums[index] = '合计'
          return
        }
        const values = data.map((item) => Number(item[column.property]))
        if (!values.every((value) => Number.isNaN(value))) {
          sums[index] = `${values.reduce((prev, curr) => {
            const value = Number(curr)
            if (!Number.isNaN(value)) {
              return prev + curr
            } else {
              return prev
            }
          }, 0)}`
        } else {
          sums[index] = 'N/A'
        }
        if (index === 1 || index === 2) {
          sums[index] = ''
        }
      })

      return sums
    },
    getLabelBillDetail() {
      const labelBillIds = []
      labelBillIds.push(this.id)
      this.listQuery.labelBillIds = labelBillIds
      labelBillInfoApi.getLabelBillDetail(this.listQuery).then(res => {
        this.billDetailData = res.data
      })
    },
    exportLabelBill() {
      labelBillInfoApi.exportLabelBill(this.listQuery).then(res => {
        const excelName = this.accountName + this.dateStr + this.labelTypeName + '标签账单.xlsx'
        saveAs(res.data,excelName)
      }).catch(res => {
        this.$message.error("无法下载")
      })
    },
    handleEdit(data) {
      this.dialogVisible = true
      this.formData = data
    },
    handleInput(data) {
      if (data >= this.formData.applyCount) {
        this.formData.decLabelCount = this.formData.applyCount
      }
    },
    decAll() {
      this.formData.decLabelCount = this.formData.applyCount
    },
    submitEdit(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.formData.labelBillId = this.id
          labelBillInfoApi.editLabelBill(this.formData).then(res => {
            this.getLabelBillInfo(this.id)
            this.getLabelBillDetail()
            this.dialogVisible = false
            this.$message.success('核销成功')
          }).catch(error => {
            this.$message.error('核销失败')
          })
        } else {
          return false;
        }
      });
    },
    editHistoryView() {
      labelBillInfoApi.getLabelBillModifyLog(this.id).then(res => {
        console.log(res.data)
        if (!res?.data?.length??0) {
          ElMessage.info('暂无修改历史')
          return false;
        }
        this.dialogVisibleView = true
        this.editLogList = res.data
      })
    }
  }
}
</script>

<style scoped lang="scss">
.top-8 {
  margin-top: 8px;
}
</style>
