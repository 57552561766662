import api from '@/apis/api'
/**
 * 查看账单相关api
 */
export class labelBillInfoApi extends api {

    getLabelBillInfo (id) {
        return this.request({
            url: `${this.servicePath}/${this.modulePath}/info/` + id,
            method: 'get'
        })
    }

    getLabelBillDetail (condition) {
        return this.request({
            url: `${this.servicePath}/${this.modulePath}/detail`,
            method: 'post',
            data: condition
        })
    }

    exportLabelBill (condition) {
        return this.request({
            url: `${this.servicePath}/${this.modulePath}/exportDetail`,
            method: 'post',
            responseType: 'blob',
            data: condition
        })
    }

    editLabelBill(formData) {
        return this.request({
            url: `${this.servicePath}/${this.modulePath}/modify`,
            method: 'post',
            data: formData
        })
    }

    getLabelBillModifyLog(labelBillId) {
        return this.request({
            url: `${this.servicePath}/${this.modulePath}/getLabelBillModifyLog/` + labelBillId,
            method: 'get'
        })
    }
}

export default new labelBillInfoApi('labelBillInfo')
